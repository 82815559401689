<template>
    <div class="h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
         style="height: 100vh; overflow: hidden">
        <div class="h-100 h-100 flex flex-column">
            <div class="flex h-100">
                <contract-side-menu :active-index="11" ref="ContractSideMenu"></contract-side-menu>
                <div class="right-panel">
                    <MetaRightSideHeader>
                        <template #title>{{'我的消息'}}</template>
                    </MetaRightSideHeader>
                    <div class="content-container shadow bg-white">
                        <MetaListTable
                                :table-data="tableData"
                                style="width: 100%;height: 100%"
                                @cell-click="checkContract"
                                :show_header="false"
                        >
                            <template #header>
                                <div class="action">
                                    <div style="text-align: right; margin-top: 10px">
                                        <h2 class="fw4 f4 gray mv0 pr4 flex items-center size-medium"></h2>
                                    </div>
                                    <div class="flex items-center pv2">
                                        <div class="nowrap mr2 ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                 viewBox="0 0 32 32"
                                                 class="meta-light-blue fill v-mid ln-title svg-size2">
                                                <path d="M24 2.667H8a2.67 2.67 0 00-2.667 2.667v5.333c0 .341.131.683.391.943L12 17.887V28a1.335 1.335 0 002.277.942l5.333-5.333c.251-.249.391-.588.391-.943v-4.781l6.276-6.276c.26-.26.391-.6.391-.943V5.333a2.67 2.67 0 00-2.667-2.667z"></path>
                                            </svg>
                                            <span class="truncate">筛选:</span>
                                        </div>
                                        <div id="attach-select-ember19" class="dib mr4">
                                            <el-select v-model="messageValue" class="m-2" placeholder="Select"
                                                       size="mini" style="width: 120px">
                                                <el-option
                                                        v-for="item in messageOptions"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div style="float: right">
                                            <el-button size="mini" @click="updateAllMessagesToChecked">一键已读</el-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="pagination-area f5">
                                    <MetaListPagination
                                            :current-count="currentCount"
                                            :size="pageSize"
                                            :current-index="paginationPageIndex"
                                            :total-count="totalCount"
                                            @current-change="handleCurrentChange"
                                    ></MetaListPagination>
                                </div>
                            </template>
                            <el-table-column
                                    align="left"
                                    prop="messageInfo"
                                    show-overflow-tooltip
                            >
                                <template #default="scope">
                                    <div class="userIcon"
                                         :class="{checked:scope.row.isCheck}">
                                        <user-icon
                                                :url="isShowUserInfoHeadImg(scope.row.messagesInfoType) ? userInfo.headImgUrl : scope.row.headImgUrl"
                                                :name="isShowUserInfoHeadImg(scope.row.messagesInfoType) ? userInfo.userName : scope.row.userName"
                                                :uid="isShowUserInfoHeadImg(scope.row.messagesInfoType) ? userInfo.userName : scope.row.userName"
                                                :size="45">
                                        </user-icon>
                                        <div style="margin-left: 15px">
                                            <span style="display: flex;" v-html="MessageInfoFormat(scope.row)"></span>
                                            <span style="display: flex;font-size: 15px;font-weight: bold">{{initSecondInfo(scope.row)}}</span>
                                            <div v-if="isShowReason(scope.row.messagesInfoType)"
                                                 style="line-height: 20px">
                                                <span v-if="scope.row.messagesInfoType===MessagesInfoType.CANCEL_CONTRACT" style="display: flex;font-weight: bold">取消理由:</span>
                                                <span v-else style="display: flex;font-weight: bold">驳回理由:</span>
                                                <span class="commentLimit">{{scope.row.reason}}</span>
                                            </div>
                                            <div v-if="MessagesInfoType.CONTRACT_TAG_COMPLETED === scope.row.messagesInfoType || MessagesInfoType.CONTRACT_TAG_COMPLETE_CANCELED === scope.row.messagesInfoType"
                                                 style="line-height: 20px">
                                                <span style="display: flex;font-weight: bold">Tag:</span>
                                                <span class="commentLimit">{{scope.row.tagName}}</span>
                                            </div>
                                            <div v-if="scope.row.comment" style="line-height: 20px;display: flex">
                                                <span v-if="isSubComment(scope.row.messagesInfoType)"
                                                      style="font-weight: bold">回复:</span>
                                                <span v-else style="font-weight: bold">评论:</span>
                                                <span class="commentLimit">{{scope.row.comment}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    prop="createTime">
                                <template #default="scope">
                                    <span :class="{checked:scope.row.isCheck}"
                                          style="display: flex;align-items: center;height: 100%; justify-content: center">{{transformTimeInfoForList(scope.row.createTime.replace(/-/g,"/"),new Date())}}</span>
                                </template>
                            </el-table-column>
                        </MetaListTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getMessages,
        updateMessagesStatusToChecked,
        // updateMessagesStatusToUnchecked,
        updateAllMessagesStatusToChecked,
    } from "../../api/api";
    import ContractSideMenu from "../../components/ContractSideMenu";
    import MetaListTable from "../../components/list/MetaListTable";
    import {colorLog, MetaMessage} from "../../util";
    import MetaRightSideHeader from "../../components/universalUI/MetaRightSideHeader";
    import MetaListPagination from "../../components/list/MetaListPagination";
    import UserIcon from "../../components/UserIcon";
    import {MessagesInfoType, SourceType} from "../../constant/messages/MessagesInfoType";
    import {transformTimeInfoForList} from "../../util";


    export default {
        name: "ContractMessages",
        components: {MetaListPagination, MetaRightSideHeader, ContractSideMenu, MetaListTable, UserIcon},
        data() {
            return {
                MessagesInfoType,
                SourceType,
                messageOptions: [
                    {
                        "label": "未读",
                        "value": 0
                    },
                    {
                        "label": "最新",
                        "value": 1
                    },
                ],
                messageValue: !isNaN(this.$route.query.messageValue) ? parseInt(this.$route.query.messageValue) : 0,
                metaMessage: new MetaMessage(),
                currentIndex: !isNaN(this.$route.query.currentIndex) ? parseInt(this.$route.query.currentIndex) : 1,
                paginationPageIndex: 1,
                pageSize: 20,
                totalCount: 0,
                totalPage: 0,
                currentCount: 0,
                tableData: [],
                userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
            }
        },
        created() {
            this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        },
        mounted() {
            // this.loadData();
        },
        watch: {
            '$route.query.currentIndex': function (toIndex) {
                // colorLog.$1('toIndex',toIndex);
            },
            currentIndex: {
                handler() {
                    this.loadData();
                },
                deep: true,
                immediate: true
            },
            messageValue: {
                handler() {
                    this.loadData();
                },
                deep: true,
            }
        }
        ,
        methods: {
            transformTimeInfoForList,
            loadData: function () {
                const columnName = this.messageValue == 1 ? 'createTime' : 'isCheck';
                getMessages({
                    columnName: columnName,
                    pageIndex: this.currentIndex,
                    pageSize: this.pageSize
                }).then(res => {
                    if (res.data.code === 0) {
                        this.tableData = res.data.data.content;
                        this.totalCount = res.data.data.totalElements;
                        this.paginationPageIndex = this.currentIndex;
                        this.currentCount = res.data.data.numberOfElements;
                        this.$router.push({
                            name: 'messages',
                            query: {currentIndex: this.currentIndex, messageValue: this.messageValue}
                        })
                    } else {
                        this.metaMessage['error']('查询数据失败');
                    }
                }).catch(err => {
                    console.error('getContractDates   err ==== ', err);
                    this.metaMessage['error']('查询数据失败');
                });
            },
            handleCurrentChange(newPaginationPageIndex) {
                this.currentIndex = newPaginationPageIndex;
            },
            openNewPage: function (name, query) {
                let newPage = this.$router.resolve({
                    name: name,
                    query: query
                })
                window.open(newPage.href)
            },
            checkContract: function (row, column) {
                if (row.isCheck === 0) {
                    updateMessagesStatusToChecked(row.id).then(res => {
                        if (res.status === 200) {
                            row.isCheck = 1;
                            this.pushEmit();
                            if (row.url != null) {
                                window.open(row.url)
                            } else {
                                if (row.sourceType === SourceType.CONTRACT) {
                                    if (row.messagesInfoType === MessagesInfoType.ADD_COMMENT
                                        || row.messagesInfoType === MessagesInfoType.ADD_SUB_COMMENT
                                        || row.messagesInfoType === MessagesInfoType.ADD_COMMENT_WITHIN_APPROVAL){
                                        this.$router.push({name: 'contractView', query: {id: row.documentId,toolActiveName : "comments",markId : row.markId}});
                                    }else{
                                        this.$router.push({name: 'contractView', query: {id: row.documentId}});
                                    }
                                } else if (row.sourceType === SourceType.PUBLISHED_TEMPLATE) {
                                    this.$router.push({name: 'templatePublish', query: {templateId: row.documentId}});
                                } else {
                                    //不跳转
                                }
                            }
                        }
                    }).catch(e => {
                        this.metaMessage['error']('网络错误');
                    });
                } else {
                    if (row.url != null) {
                        window.open(row.url)
                    } else {
                        if (row.sourceType === SourceType.CONTRACT) {
                            if (row.messagesInfoType === MessagesInfoType.ADD_COMMENT
                                || row.messagesInfoType === MessagesInfoType.ADD_SUB_COMMENT
                                || row.messagesInfoType === MessagesInfoType.ADD_COMMENT_WITHIN_APPROVAL){
                                this.$router.push({name: 'contractView', query: {id: row.documentId,toolActiveName : "comments",markId : row.markId}});
                            }else {
                                this.$router.push({name: 'contractView', query: {id: row.documentId}});
                            }
                        } else if (row.sourceType === SourceType.PUBLISHED_TEMPLATE) {
                            this.$router.push({name: 'templatePublish', query: {templateId: row.documentId}});
                        } else {
                            //不跳转
                        }
                    }
                }
            },
            MessageInfoFormat: function (row) {
                let messagesInfoType = row.messagesInfoType;
                switch (messagesInfoType) {
                    //后端枚举 -> 前端枚举
                    case MessagesInfoType.AWAIT_APPROVAL:
                        return "您有一份合同待审批";
                    case MessagesInfoType.AWAIT_PUBLISHED_TEMPLATE_APPROVAL:
                    case MessagesInfoType.REPUBLISH_PUBLISHED_TEMPLATE:
                        return "您有一份模板待审批";
                    case MessagesInfoType.ACCEPT_PUBLISHED_TEMPLATE_APPROVAL:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp通过了您发布的模板";
                    case MessagesInfoType.REJECT_PUBLISHED_TEMPLATE_APPROVAL:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp驳回了您发布的模板";
                    case MessagesInfoType.SET_READY_TO_SIGN:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp将合同设为定稿";
                    case MessagesInfoType.CONTRACT_SIGNED:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp将合同设为已签署";
                    case MessagesInfoType.ACCEPT_APPROVAL:
                        if (row.documentCreatorId === this.userInfo.uid) {
                            return "您发起的合同审批通过";
                        } else {
                            return "合同审批通过";
                        }
                    case MessagesInfoType.REJECT_APPROVAL:
                        if (row.documentCreatorId === this.userInfo.uid) {
                            return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp驳回了您发起的合同"
                        } else {
                            return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp驳回了合同"
                        }
                    case MessagesInfoType.ADD_COLLABORATOR:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp添加你为合同的协作者";
                    case MessagesInfoType.JOIN_TEAM:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp成员加入了团队";
                    case MessagesInfoType.JOIN_TEAM_FAIL:
                        return "加入团队失败";
                    case MessagesInfoType.DIFF_DIFFERENT:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp上传了已签署版合同,与定稿版存在<span style='color: #ff0000'>" + row.diffCount + "</span>处不同";
                    case MessagesInfoType.ADD_COMMENT:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp评论了您发布的合同";
                    case MessagesInfoType.ADD_SUB_COMMENT:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp回复了您的评论";
                    case MessagesInfoType.ADD_COMMENT_WITHIN_APPROVAL:
                        return "待您审批的合同新增了一条评论";
                    case MessagesInfoType.CONTRACT_REVERT:
                        return '合同已被退回到"草稿"状态';
                    case MessagesInfoType.ACCEPT_STAGE_APPROVAL:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp在审批步骤" +row.sortIndex+ "中通过了审批";
                    case MessagesInfoType.CANCEL_CONTRACT:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp取消了合同";
                    case MessagesInfoType.UPLOAD_INVOICE:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp为合同上传了发票";
                    case MessagesInfoType.CONTRACT_BULLETIN_CREATED:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp为合同创建了公告";
                    case MessagesInfoType.CONTRACT_BULLETIN_UPDATED:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp修改了合同的公告";
                    case MessagesInfoType.CONTRACT_BULLETIN_DELETED:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp删除了合同的公告";
                    case MessagesInfoType.CONTRACT_TAG_COMPLETED:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp完成了合同的Tag待办事项";
                    case MessagesInfoType.CONTRACT_TAG_COMPLETE_CANCELED:
                        return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp取消了合同的Tag待办事项";
                    default :
                        return "数据陈旧,无法正确显示数据";
                }
            },
            initSecondInfo: function (row) {
                let messagesInfoType = row.messagesInfoType;
                return MessagesInfoType.JOIN_TEAM === messagesInfoType || MessagesInfoType.JOIN_TEAM_FAIL === messagesInfoType
                    ? row.teamName : row.documentName;
            },
            isShowReason: function (messagesInfoType) {
                return MessagesInfoType.REJECT_APPROVAL === messagesInfoType
                    || MessagesInfoType.REJECT_PUBLISHED_TEMPLATE_APPROVAL === messagesInfoType
                    || MessagesInfoType.CANCEL_CONTRACT === messagesInfoType;
            },
            isSubComment: function (messagesInfoType) {
                return MessagesInfoType.ADD_SUB_COMMENT === messagesInfoType;
            },
            isShowUserInfoHeadImg:function(messagesInfoType){
                return messagesInfoType === MessagesInfoType.AWAIT_APPROVAL
                    || messagesInfoType === MessagesInfoType.AWAIT_PUBLISHED_TEMPLATE_APPROVAL
                    || messagesInfoType === MessagesInfoType.JOIN_TEAM_FAIL;
            },
            updateAllMessagesToChecked: function () {
                updateAllMessagesStatusToChecked().then(res => {
                    if (res.status == 200) {
                        this.metaMessage['success']('已清空未读');
                        this.pushEmit();
                        for (const row in this.tableData) {
                            this.tableData[row].isCheck = 1
                        }
                    }
                }).catch(e => {
                    console.log(e);
                    this.metaMessage['error'].error('网络错误');
                });
            },
            pushEmit: function () {
                this.$refs["ContractSideMenu"].refreshCount = true
            }
        }
    }
</script>

<style scoped>
    .message-row-operation {
        color: var(--lightBlue);
    }

    .message-row-operation:hover {
        color: var(--darkBlue);
    }

    .right-panel {
        text-align: left;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 2rem;
        height: 100%;
        width: calc(100% - 283px);
    }

    .content-container {
        flex: 1;
    }

    .header-right {
        display: flex;
        align-items: center;
    }

    .content-header {
        display: flex;
        border-bottom: solid 1px #dddddd;
        padding: 7px 0;
    }

    .header-left {
        flex: 1;
    }


    .date-date {
        text-align: right;
    }

    .date-event {
        cursor: pointer;
        /*background-color: rgb(40, 146, 254);*/
        /*color: white;*/
        background-color: rgb(225, 234, 255);
        color: rgb(28, 76, 186);
        display: flex;
        align-items: center;
        margin-bottom: 1px;
    }

    .date-event-more {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 1px;
    }

    .processed-event {
        background-color: #E6E6E6;
        color: #AAAAAA;
    }

    .events-popover-header {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
    }

    .event-edit-dialog-header {
        position: relative;
        flex-direction: column;
        width: 100%;
        padding-bottom: 16px;
        background-size: cover;
        flex: none;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
    }

    .tool-btns {
        display: flex;
        margin: 13px 16px 0 0;
        flex: none;
        align-self: flex-end;
        justify-content: space-between;
        align-items: center;
    }

    .btn-img {
        width: 16px;
        height: 16px;
    }

    .tool-btns .btn-img-wrapper:not(:last-child) {
        margin-right: 16px;
    }

    .btn-img-wrapper {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: #fff;
        line-height: 16px;
        cursor: pointer;
    }

    .btn-status-group {
        margin-right: 16px;
        color: #fff;
    }

    .summary {
        position: relative;
        font-size: 20px;
        padding: 40px 16px 0;
        margin: 0 0 10px;
        font-weight: 600;
        line-height: 26px;
        user-select: text;
        color: #fff;
        flex: none;
        align-self: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        box-sizing: border-box;
        word-break: break-word;
    }

    .event-detail--time {
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        word-break: break-word;
        white-space: pre-wrap;
        user-select: text;
    }

    .eventDetail--entranceContainer {
        margin-top: 12px;
        display: flex;
        align-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
    }

    .event-edit-dialog-content {
        padding: 10px 0 8px;
        user-select: text;
        overflow-y: overlay;
        overflow-x: hidden;
        width: 100%;
        box-sizing: border-box;
        flex: auto;
    }

    .detail-row {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 0;
        flex: none;
        align-items: center;
        justify-content: space-between;
    }

    .detail-row .icon {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 15px;
        line-height: 16px;
        font-size: 12px;
        flex: none;
        color: #8f959e;
        align-self: flex-start;
        cursor: default;
    }

    .events-popover-day {
        font-weight: bold;
        font-size: 18px;
        padding: 5px 0;
    }

    .detail-content {
        margin-right: 16px;
        font-size: 12px;
        white-space: pre-wrap;
        word-break: break-word;
        width: calc(100% - 55px);
        color: #1f2329;
        align-self: center;
        flex: auto;
    }

    .meta-dialog {
        position: fixed;
        z-index: 999999999;
        top: 15vh;
        left: 35%;
        width: 30%;
        text-align: left;
        background: #ffffff;
        border-radius: 2px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
    }

    .meta-dialog .event-edit-dialog-header {
        padding: 0;
        background-image: linear-gradient(to right bottom, rgb(78, 131, 253), rgb(51, 112, 255));
    }

    .date-event-img {
        width: 16px;
        height: 16px;
        /*display: inline-block;*/
    }

    .date-event-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 1px;
    }

    .userIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin-left: 30px;
    }

    .checked {
        opacity: .5;
    }

    .commentLimit {
        display: -webkit-box;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

</style>
<style scoped>
    .el-calendar-day {
        padding: 4px !important;
    }

    /deep/ .el-table {
        color: var(--font-black);
        font-size: 14px;
    }

    .content-container >>> .meta-list-table .el-table .cell[class] {
        height: 120px;
        line-height: 25px;
    }
</style>
